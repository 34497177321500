import Button from "components/basic/button";
import { FlexRow } from "components/basic/flex";
import Separator from "components/basic/separator";
import SelectField from "components/form/custom-fields/select-field";
import FormSubmitBehavior from "components/form/form-submit";
import { useStepsContext } from "components/steps-controller/steps-context";
import TextAreaField from "components/form/custom-fields/text-area-field";
import {
  earlyAdopterContext,
  EarlyAdopterValues,
  useStoreSetters,
} from "../context";
import {
  EarlyAdopterRegistration,
  EarlyAdopterService,
} from "services/requests";
import { useState } from "react";
import { LoadingPage } from "pages/other/loading-page";

type CompanyProps = {
  previousStepId: string;
  nextStepId: string;
};

const Company: React.FC<CompanyProps> = ({ previousStepId, nextStepId }) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const { updateValues } = useStoreSetters();
  const { useStore } = earlyAdopterContext;
  const values = useStore((state) => state.values);

  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) return <LoadingPage />;

  return (
    <>
      <SelectField
        name="amount_properties"
        label="Quantas propriedades você administra?"
        options={{
          option1: { value: "De 1 a 10 imóveis" },
          option2: { value: "De 10 a 20 imóveis" },
          option3: { value: "De 20 a 50 imóveis" },
          option4: { value: "De 50 a 100 imóveis" },
          option5: { value: "De 100 a 500 imóveis" },
          option6: { value: "De 500 a 1000 imóveis" },
          option7: { value: "Mais de 1000" },
          option8: { value: "Não se aplica" },
        }}
        sx={{ width: "100%" }}
      />

      <SelectField
        name="amount_employees"
        label="Quantos colaboradores trabalham na sua empresa?"
        options={{
          option1: { value: "De 1 a 5" },
          option2: { value: "De 5 a 10" },
          option3: { value: "De 10 a 20" },
          option4: { value: "Mais de 20" },
          option5: { value: "Não se aplica" },
        }}
        sx={{ width: "100%" }}
      />
      <TextAreaField
        name="search_improve"
        label="O que você busca para melhorar a gestão do seu negócio imobiliário?"
        type="text"
        rows={2}
        sx={{ width: "100%" }}
      />

      <Separator sx={{ marginBottom: "30px" }} />
      <FlexRow sx={{ gap: 2 }}>
        <Button
          variant="outlined"
          sx={{ flexBasis: 1, flexGrow: 1, fontSize: "15px" }}
          onClick={() => setCurrentStepId(previousStepId)}
        >
          Voltar
        </Button>
        <FormSubmitBehavior
          onSubmit={(context) => onSubmit(context.formValues)}
        >
          <Button sx={{ flexBasis: 1, flexGrow: 1, fontSize: "15px" }}>
            Finalizar
          </Button>
        </FormSubmitBehavior>
      </FlexRow>
    </>
  );

  async function onSubmit(formValues: EarlyAdopterValues) {
    updateValues(formValues);
    console.log(values);

    const earlyadopterDatas = {
      ...values,
      ...formValues,
      ocupation:
        (formValues.ocupation === "Outra"
          ? formValues.other_ocupation
          : formValues.ocupation) ?? "",
      important_function:
        (formValues.important_function === "Outras"
          ? formValues.others_important_function
          : formValues.important_function) ?? "",
    } as EarlyAdopterRegistration;

    try {
      setIsLoading(true);
      const response = await EarlyAdopterService.postEarlyAdopter(
        earlyadopterDatas
      );
      console.log(response);
      setCurrentStepId(nextStepId);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }
};

export default Company;
