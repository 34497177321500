import * as jose from "jose";


const REACT_APP_AUTHORITY_URL: string = process.env.REACT_APP_AUTHORITY_URL ?? '';
const REACT_APP_CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID ?? '';

export const getAuthToken = () => {
  const user_datas_key = `oidc:useruser:${REACT_APP_AUTHORITY_URL}:${REACT_APP_CLIENT_ID}`;
  const user_datas = JSON.parse(window.localStorage.getItem(user_datas_key) || '{}');
  const token = user_datas?.access_token;
  return token
};

export const initialize = async (
  rsaKeyPrivate: jose.JWK,
  rsaKeyPublic: jose.JWK
) => {
  const validToken = await createJWTMock(rsaKeyPrivate);
  const result = await verifyToken(validToken, rsaKeyPublic);
  console.log(result);
};

export async function verifyToken(jwt: string, rsaKeyPublic: jose.JWK) {
  const key = await jose.importJWK(rsaKeyPublic, "RS256");

  try {
    const { payload } = await jose.jwtVerify(jwt, key);
    console.log("Token is valid:", payload);
    return true;
  } catch (err) {
    console.error("Token verification failed:", err);
  }
}

export async function createJWTMock(mySuperKey: jose.JWK): Promise<string> {
  let jwt = "";
  try {
    const privateKey = await jose.importJWK(mySuperKey, "RS256");

    const payload = {
      iss: "your-issuer",
      sub: "your-subject",
      aud: "your-audience",
      exp: Math.floor(Date.now() / 1000) + 60 * 60, // 1 hour expiration
      iat: Math.floor(Date.now() / 1000),
      customClaim: "customValue",
    };

    jwt = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg: "RS256" })
      .sign(privateKey);
  } catch (error) {
    console.error("Erro:", error);
  }
  console.log(jwt);
  return jwt;
}
