import createLocalZustand from "context/local-zustand";
import { produce } from "immer";
import { useShallow } from "zustand/react/shallow";

export interface EarlyAdopterValues {
  other_ocupation?: string;
  ocupation?: string;
  city?: string;
  state?: string;
  important_function?: string;
  others_important_function?: string;
  amount_properties?: string;
  amount_employees?: string;
  search_improve?: string;
}

interface EarlyAdopterContextType {
  values: EarlyAdopterValues;
  set: HandleChangeStore;
  get: () => EarlyAdopterContextType;
  setValues: HandleChangeValues;
  updateValues: HandleChangeValues;
}

export type HandleChangeStore = (
  partial:
    | EarlyAdopterContextType
    | Partial<EarlyAdopterContextType>
    | ((
        state: EarlyAdopterContextType
      ) => EarlyAdopterContextType | Partial<EarlyAdopterContextType>),
  replace?: boolean | undefined
) => void;

export type HandleChangeValues = (value: EarlyAdopterValues) => void;

export const earlyAdopterContext = createLocalZustand<EarlyAdopterContextType>(
  (initializer) => (set, get) => ({
    values: { ...initializer.values },

    set: set,
    get: get,

    setValues: (values) =>
      set((state) => {
        const newState = produce(state, (draft) => {
          draft.values = values;
        });
        return newState;
      }),

    updateValues: (newValues) =>
      set((state) => {
        const { values } = get();
        const newState = produce(state, (draft) => {
          draft.values = { ...values, ...newValues };
        });
        return newState;
      }),
  })
);

const { useStore } = earlyAdopterContext;

export const useStoreSetters = (): Pick<
  EarlyAdopterContextType,
  "set" | "setValues" | "updateValues"
> => {
  return useStore(
    useShallow((state) => ({
      set: state.set,
      setValues: state.setValues,
      updateValues: state.updateValues,
    }))
  ) as {
    set: HandleChangeStore;
    setValues: HandleChangeValues;
    updateValues: HandleChangeValues;
  };
};
