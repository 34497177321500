import Button from "components/basic/button";
import { FlexRow } from "components/basic/flex";
import Separator from "components/basic/separator";
import SelectField, {
  convertArrayToSelectFieldOptions,
} from "components/form/custom-fields/select-field";
import TextField from "components/form/custom-fields/text-field";
import {
  useFormStore,
  useFormStoreSetters,
} from "components/form/form-store";
import FormSubmitBehavior from "components/form/form-submit";
import { useStepsContext } from "components/steps-controller/steps-context";
import { FormEvent } from "react";
import { citys_brasil, UFs_brasil } from "services/housien/constants";
import { useStoreSetters } from "../context";

interface FormValuesType {
  other_ocupation?: string;
  ocupation?: string;
  city?: string;
  state?: string;
  important_function?: string;
  others_important_function?: string;
}

type UserTypeProps = {
  previousStepId: string;
  nextStepId: string;
};

const UserType: React.FC<UserTypeProps> = ({ previousStepId, nextStepId }) => {
  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;
  const { updateValues } = useStoreSetters();
  const { setFieldValue } = useFormStoreSetters();

  const formValues = useFormStore(state => state.formValues);

  const handleUfChange = (e: FormEvent<HTMLInputElement>) => {
    setFieldValue("city", "");
  };

  return (
    <>
      <SelectField
        name="ocupation"
        label="Qual sua ocupação?"
        options={{
          option1: { value: "Corretor Individual" },
          option2: { value: "Agência Imobiliária" },
          option3: { value: "Construtora" },
          option4: { value: "Investidor Imobiliário" },
          option5: { value: "Outra" },
        }}
        sx={{ width: "100%" }}
      />

      {formValues.ocupation === "Outra" && (
        <TextField
          name="other_ocupation"
          label="Qual?"
          sx={{ width: "100%" }}
        />
      )}

      <FlexRow sx={{ gap: 1 }}>
        {/* <TextField
          name="city"
          label="Cidade"
          sx={{ width: "100%" }}
          validation={({ value }) =>
            !ufSelected
              ? "Selecione um Estado"
              : citys_brasil[ufSelected as keyof Citys_Brasil].includes(value)
              ? undefined
              : "Digite uma cidade válida conforme o Estado"
          }
        /> */}
        <SelectField
          name="state"
          type="search"
          label="Estado"
          options={UFs_brasil}
          onChange={handleUfChange}
          sx={{ width: "40%" }}
        />
        <SelectField
          name="city"
          label="Cidade"
          type="search"
          options={
            formValues.state
              ? convertArrayToSelectFieldOptions(citys_brasil[formValues.state] ?? [])
              : {}
          }
          sx={{ width: "100%" }}
          // validation={({ value }) =>
          //   !ufSelected ? "Selecione um Estado" : undefined
          // }
        />
      </FlexRow>

      {/* <FieldGroup
        name="important_function"
        label="Qual funcionalidade você considera mais importante para seu negócio?"
        wrapperSx={{ width: "100%" }}
        cardSx={{ gap: 0 }}
      >
        <RadioField
          name="radio-group"
          value="Gestão do portfólio de imóveis"
          label="Gestão do portfólio de imóveis"
        />
        <RadioField
          name="radio-group"
          value="Gestão de aluguéis, pagamentos, locadores e locatários/inquilinos"
          label="Gestão de aluguéis, pagamentos, locadores e locatários/inquilinos"
        />
        <RadioField
          name="radio-group"
          value="Gestão de vendas, prospecção de novos clientes, qualificação de clientes e negociações"
          label="Gestão de vendas, prospecção de novos clientes, qualificação de clientes e negociações"
        />
        <RadioField name="radio-group" value="Outra" label="Outra" />
      </FieldGroup> */}

      <SelectField
        name="important_function"
        label="Qual funcionalidade você considera mais importante para seu negócio?"
        options={{
          option1: { value: "Gestão do portfólio de imóveis" },
          option2: {
            value:
              "Gestão de aluguéis, pagamentos, locadores e locatários/inquilinos",
          },
          option3: {
            value:
              "Gestão de vendas, prospecção de novos clientes, qualificação de clientes e negociações",
          },
          option4: { value: "Outras" },
        }}
        sx={{ width: "100%" }}
      />

      {formValues.important_function === "Outras" && (
        <TextField
          name="others_important_function"
          label="Quais?"
          sx={{ width: "100%" }}
        />
      )}

      <Separator sx={{ marginBottom: "30px" }} />
      <FlexRow sx={{ gap: 2 }}>
        {/* <FlexFill /> */}
        <Button
          variant="outlined"
          sx={{ flexBasis: 1, flexGrow: 1, fontSize: "15px" }}
          onClick={() => setCurrentStepId(previousStepId)}
        >
          Voltar
        </Button>
        <FormSubmitBehavior onSubmit={(state) => onSubmitUserTypeForm(state.formValues)}>
          <Button
            preventDefault={true}
            sx={{ flexBasis: 1, flexGrow: 1, fontSize: "15px" }}
          >
            Próximo
          </Button>
        </FormSubmitBehavior>
      </FlexRow>
    </>
  );

  function onSubmitUserTypeForm(formValues: FormValuesType) {
    updateValues(formValues);
    setCurrentStepId(nextStepId);
  }
};

export default UserType;
