import TextField from "components/form/custom-fields/text-field";
import { useFormStoreSetters } from "components/form/form-store";
import Button from "components/basic/button";
import Base from "components/basic/base";
import { cepMask, cnpjMask } from "utils/input-masks";
import { useState } from "react";
import getCepData, { CepData } from "services/get-cep-data";

import Text from "components/basic/text";
import LoadingAdornment from "components/form/primitives/input-adornment/loading";
import { useStepsContext } from "components/steps-controller/steps-context";
import TextAreaField from "components/form/custom-fields/text-area-field";
import FormSubmitBehavior from "components/form/form-submit";
import { AgenciesService, AgencyInput } from "services/requests";
import { CardWithSections } from "components/basic/card/card";
import CardHeader from "components/basic/card/card-header";
import { CardSection } from "components/basic/card/card-section";
import { LoadingPage } from "pages/other/loading-page";
import { AxiosError } from "axios";

type MaskFunction = (value: string) => {
  unmasked: string;
  masked: string;
};

export const numeroMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = `N°${unmasked}`;
  return { unmasked, masked };
};

type AgencyProps = {
  nextStepId: string;
};
const RegisterAgency: React.FC<AgencyProps> = ({ nextStepId }) => {
  const [cepLoading, setCepLoading] = useState(false);

  const { updateValues } = useFormStoreSetters();

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const [isValidCep, setIsValidCep] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidID, setIsValidID] = useState(true);

  if (isLoading) return <LoadingPage />;

  return (
    <Base
      sx={{
        width: "100%",
        flexGrow: 1,
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
        gap: 1,
        "@media(sm-)": {
          background: "red",
        },
        alignItems: "start",
      }}
    >
      <CardWithSections
        variant="outlined"
        direction="vertical"
        size="small"
        sx={{ gridColumn: "span 6" }}
      >
        <CardHeader sx={{ p: 1 }}>
          <Text variant="h5">Dados Comerciais</Text>
        </CardHeader>
        <CardSection sx={{ p: 1 }}>
          <TextField
            sx={{ width: "100%" }}
            name="name"
            label="Nome comercial"
          />
          <TextField
            sx={{ width: "100%" }}
            name="id"
            label="Identificação única ID (opcional)"
            onFieldChange={() => setIsValidID(true)}
            validationMoment="both"
            validation={() =>
              !isValidID ? "Este ID já está em uso." : undefined
            }
          />
          <TextField
            sx={{ width: "100%" }}
            name="cnpj"
            label="CNPJ"
            mask={cnpjMask}
            placeholder="00.000.000/0000-00"
          />
          <TextAreaField
            sx={{ width: "100%" }}
            name="description"
            label="Descrição (opcional)"
            type="text"
            rows={1}
          />
        </CardSection>
      </CardWithSections>

      <CardWithSections
        variant="outlined"
        direction="vertical"
        size="small"
        sx={{ gridColumn: "span 12" }}
      >
        <CardHeader sx={{ p: 1 }}>
          <Text variant="h5">Endereço da Imobiliária</Text>
        </CardHeader>
        <CardSection
          sx={{
            p: 1,
            display: "grid",
            gridTemplateColumns:
              "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
            gap: 1,
          }}
        >
          <TextField
            name="postal_code"
            label="CEP"
            mask={cepMask}
            placeholder="0000-000"
            sx={{ gridColumn: "span 3" }}
            type="text"
            onFieldChange={({ value }) => onCEPFilled(value)}
            rightAdornment={cepLoading && <LoadingAdornment />}
            disabled={cepLoading}
            validationMoment="blur"
            validation={() =>
              !isValidCep ? "Digite um CEP válido" : undefined
            }
          />

          <TextField
            name="street"
            label="Logradouro"
            sx={{ gridColumn: "span 9" }}
            rightAdornment={cepLoading && <LoadingAdornment />}
            disabled={cepLoading}
          />

          <TextField
            name="number"
            label="Número"
            sx={{ gridColumn: "span 2" }}
            mask={numeroMask}
          />

          <TextField
            name="complement"
            label="Complemento (opcional)"
            sx={{ gridColumn: "span 3" }}
          />

          <TextField
            name="neighborhood"
            label="Bairro"
            sx={{ gridColumn: "span 7" }}
            rightAdornment={cepLoading && <LoadingAdornment />}
            disabled={cepLoading}
          />

          <TextField
            name="city"
            label="Cidade"
            sx={{ gridColumn: "span 8" }}
            rightAdornment={cepLoading && <LoadingAdornment />}
            disabled={cepLoading}
          />

          <TextField
            name="state"
            label="Estado"
            sx={{ gridColumn: "span 4" }}
            rightAdornment={cepLoading && <LoadingAdornment />}
            disabled={cepLoading}
          />
        </CardSection>
      </CardWithSections>

      <FormSubmitBehavior
        onSubmit={(state) => {
          const { cep, ...agency_datas } = state.formValues;
          onSubmitForm(agency_datas as AgencyInput);
        }}
      >
        <Button
          sx={{ gridColumn: "span 12", width: "100%" }}
          color="primary.main"
        >
          + Adicionar
        </Button>
      </FormSubmitBehavior>
    </Base>
  );

  async function onSubmitForm(formValues: AgencyInput) {
    const agency_datas: AgencyInput = {
      ...formValues,
      image: undefined, //TODO implementar upload de imagem
    };
    try {
      setIsLoading(true);
      await AgenciesService.createAgency(agency_datas);
      setCurrentStepId(nextStepId);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.status === 400) setIsValidID(false);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onCEPFilled(value: string) {
    if (value.length === 8) {
      try {
        setCepLoading(true);
        const cepData = await getCepData(value);
        const { logradouro, bairro, localidade, uf, cep } =
          cepData?.data as CepData;
        const newValues = {
          postal_code: cep,
          street: logradouro,
          neighborhood: bairro,
          city: localidade,
          state: uf,
        };
        updateValues(newValues);
        setIsValidCep(true);
      } catch (error) {
        setIsValidCep(false);
        console.error(error);
      } finally {
        setCepLoading(false);
      }
    }
  }
};

export default RegisterAgency;
